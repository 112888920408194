body {
  text-shadow: #fff 0px 1px 1px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  letter-spacing: 0.03333em;

  font-family: 'Oxygen', sans-serif;
}

html * {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

p {
  line-height: 1.5;
}

#root {
  background: #ffffff;
  position: relative;
  z-index: 1;
}
